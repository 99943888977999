import React from "react";
import Popularity from "./popularity";

const Brewery = (d) => {
    return (
        <div class={`flex flex-row border-4 rounded-xl mb-4 p-4 pb-0 fb-quality-${d.data.fbQuality}`}>
            <div className="mr-4 mb-4"><img className="w-24 h-24" src={d.data.utBreweryLogo} loading="lazy"/></div>
            <div class="basis-3/4">
               <h3 className="text-xl text-gray-800 font-bold leading-none mb-2">{d.idx + 1}. {d.data.utBreweryName}</h3>
               <h4 className="text-lg text-gray-600 font-bold leading-none mb-2">{d.data.city}{d.data?.city?.length ? ",":""} {d.data.state}{d.data?.state?.length ? ",":""} {d.data.country}</h4>
               <p className="text-gray-600 mb-2">{d.data.utBreweryDescription}</p>
               <div className={`mb-4 sm:flex fb-quality-${d.data.fbQuality} border-l-8 pl-4`}>
                   <div className="flex-0 w-28 whitespace-nowrap">RATING: {d.data.fbScore}</div>
                   <div className="flex-0 w-40 whitespace-nowrap"><Popularity utRatings={d.data.utRatings}/></div>
                   <div className="flex-0 whitespace-nowrap"><a className="underline" href={`https://untappd.com/brewery/${d.data.utId}`}>Visit on Untappd</a></div>
                </div>
            </div>
        </div>
    )
}

export default Brewery;